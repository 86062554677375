import { Box, SxProps, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTransactionDetail } from "../data/transaction.api";
import { useParams } from "react-router-dom";
import { RootState } from "../../../store";
import moment from "moment";
import moneyParser from "../../../shared/moneyParser";
import { InterfaceTransactionData } from "../data/transaction.interface";

export default function TransactionPrint(props: { data?: InterfaceTransactionData }) {

    const dispatch = useDispatch<any>()
    const { id } = useParams()

    const { transactionDetail } = useSelector((state: RootState) => state.transaction)

    useEffect(() => {
        if (id) {
            dispatch(getTransactionDetail(parseInt(id)))
        }
    }, [dispatch, id])

    const data = props.data || transactionDetail

    const cellSx: SxProps = { padding: "4px" }

    if (data.pay_list.length === 0) {
        return <></>
    }

    return (
        <Box position={"relative"}>
            {data.total_paid < data.total && (
                <Typography
                    variant="h2"
                    color="textSecondary"
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        zIndex: 1,
                        opacity: 0.5
                    }}
                >
                    Belum Lunas
                </Typography>
            )}
            <Table padding="none">
                <TableBody>
                    <TableRow>
                        <TableCell sx={cellSx} colSpan={2}>
                            <Typography variant="h6" align="center">KSL Grand Ballroom</Typography>
                            <Typography variant="h6" align="center">Papua</Typography>
                            <Typography variant="h6" align="center">082155155998</Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={cellSx}>Kode Booking</TableCell>
                        <TableCell sx={cellSx} align="right">{data.id}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={cellSx}>Tanggal Booking</TableCell>
                        <TableCell sx={cellSx} align="right">{moment(data.date_book).format("YYYY-MM-DD")}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={cellSx}>Pelanggan</TableCell>
                        <TableCell sx={cellSx} align="right">{data.customer.name}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={cellSx} colSpan={2}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={cellSx}>Nama Paket</TableCell>
                                        <TableCell sx={cellSx}>Harga Satuan</TableCell>
                                        <TableCell sx={cellSx}>Quantity</TableCell>
                                        <TableCell sx={cellSx}>Total</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.package_list.map((pkg, i) => (
                                        <TableRow key={i}>
                                            <TableCell sx={cellSx}>{pkg.package.name}</TableCell>
                                            <TableCell sx={cellSx} align="right">{moneyParser(pkg.price)}</TableCell>
                                            <TableCell sx={cellSx}>{pkg.quantity}</TableCell>
                                            <TableCell sx={cellSx} align="right">{moneyParser(pkg.price * pkg.quantity)}</TableCell>
                                        </TableRow>
                                    ))}
                                    <TableRow>
                                        <TableCell sx={{ ...cellSx, fontWeight: "bold" }} align="right" colSpan={3}>Total :</TableCell>
                                        <TableCell sx={{ ...cellSx, fontWeight: "bold" }} align="right">{moneyParser(data.total)}</TableCell>
                                    </TableRow>
                                    {data.total_paid < data.total && (
                                        <Fragment>
                                            <TableRow>
                                                <TableCell sx={{ ...cellSx, fontWeight: "bold" }} align="right" colSpan={3}>Pembayaran :</TableCell>
                                                <TableCell sx={{ ...cellSx, fontWeight: "bold" }} align="right">{moneyParser(data.total_paid)}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={{ ...cellSx, fontWeight: "bold" }} align="right" colSpan={3}>Sisa Pelunasan :</TableCell>
                                                <TableCell sx={{ ...cellSx, fontWeight: "bold" }} align="right">{moneyParser(data.total - data.total_paid)}</TableCell>
                                            </TableRow>
                                        </Fragment>
                                    )}
                                </TableBody>
                            </Table>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={cellSx}>Tanggal Pelunasan :</TableCell>
                        <TableCell sx={cellSx} align="right">
                            {data.total_paid < data.total
                                ? (
                                    '-'
                                )
                                : (
                                    moment(data.pay_list[data.pay_list.length - 1].ts_created).format("YYYY-MM-DD")
                                )
                            }
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <Box display={"flex"} justifyContent={"space-evenly"} marginTop={4}>
                <Box display={"flex"} flexDirection={"column"} alignItems={"center"} gap={9}>
                    <Typography>Hormat Kami,</Typography>
                    <Typography>(........................)</Typography>
                </Box>
                <Box display={"flex"} flexDirection={"column"} alignItems={"center"} gap={9}>
                    <Typography>Penyewa,</Typography>
                    <Typography>(........................)</Typography>
                </Box>
            </Box>
        </Box>
    )
}